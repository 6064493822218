<template>
  <div>
    <b-card class="mt-0">
      <b-row
        class="mb-3 border-bottom"
      >
        <b-col
          cols="6"
          class="pb-1"
        >
          <div>
            <feather-icon
              icon="ListIcon"
              size="20"
              class="text-primary"
            /> <span
              class="text-primary"
              style="font-size:17px"
            ><strong>FARMACIA - MEDICAMENTOS</strong></span>
          </div>
        </b-col>

        <b-col>
          <div class="row justify-content-end align-items-center pr-2">
            <a class="btn btn-primary btn-sm right" href="/farmacia/create">+</a>
          </div>
        </b-col>

      </b-row>

      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        id="my-table"
        ref="refTablaMedicamentos"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        small
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="fields"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> cargando...</strong>
          </div>
        </template>
        <!-- column: presentacion -->
        <template #cell(presentacion)="row">
          <span
            :class="row.item.stock > 0 ? 'font-weight-bold' : ''"
          >
            {{ row.item.presentacion.nombre }}
          </span>
        </template>
        <!-- Column: User -->
        <template #cell(nombre_corto)="row">
          <b-media vertical-align="center">
            <template #aside>
              <span v-if="row.item.lotes_disponibles.length === 0" />
              <b-avatar
                v-else-if="row.item.stock > 0 && row.item.fecha_venc_dias <= 0"
                size="32"
                :variant="`light-danger`"
              ><feather-icon
                icon="FrownIcon"
                size="19"
              /></b-avatar>
              <b-avatar
                v-else-if="row.item.stock > 0"
                size="32"
                variant="light-success"
              ><feather-icon
                icon="SmileIcon"
                size="19"
              /></b-avatar>
              <b-avatar
                v-else
                size="32"
                variant="light-secondary"
              ><feather-icon
                icon="MehIcon"
                size="19"
              /></b-avatar>
              <!-- <b-avatar
                v-else-if="row.item.stock > 0"
                size="32"
                :variant="`light-${row.item.stock > 0 ? 'success' : 'secondary'}`"
              ><feather-icon
                :icon="row.item.stock > 0 ? 'SmileIcon' : 'MehIcon' "
                size="19"
              /></b-avatar> -->
            </template>
            <span
              :class="row.item.stock > 0 ? 'font-weight-bold' : ''"
            >
              {{ row.item.nombre_corto }}
            </span>
            <br>
            <small class="text-muted">COD: {{ row.item.cod_prod }}</small>
          </b-media>
        </template>
        <!-- column: forma -->
        <template #cell(forma)="row">
          <span :class="row.item.stock > 0 ? 'font-weight-bold' : ''">
            {{ row.item.forma.nombre_simplificado }}
          </span>
        </template>
        <!-- column: stock -->
        <template #cell(stock)="row">
          <b-badge
            :class="row.item.stock > 0 ? 'font-weight-bold' : ''"
            :variant="`light-${row.item.stock > 0 ? 'success' : 'secondary'}`"
            class="text-capitalize"
          >
            {{ row.item.stock }}
          </b-badge>
        </template>
        <!-- column: acciones -->
        <template #cell(acciones)="row">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="17"
                class="align-middle text-primary"
              />
            </template>
            <b-dropdown-item
              @click="verDetalles(row.item)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">ver detalles</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
        <!-- column: fecha vencimiento -->
        <template #cell(fecha_vencimiento)="row">

          <!-- progress bar -->
          <b-row
            v-if="row.item.stock > 0"
            class="avg-sessions pt-50"
          >
            <b-col
              v-if="row.item.fecha_venc_dias > 0"
              cols="12"
              class="mb-2"
            >
              <b-card-text class="mb-50">
                <small>resta {{ row.item.fecha_venc_dias }} dias</small>
              </b-card-text>
              <b-progress
                animated
                :value="row.item.fecha_venc_dias"
                max="30"
                height="10px"
                :variant="row.item.fecha_venc_dias > 30 ? 'success' : 'danger'"
              />
            </b-col>
            <b-col
              v-else
              cols="12"
              class="mb-2"
            >
              <b-badge variant="light-danger">
                existe un lote vencido
              </b-badge>
            </b-col>
          </b-row>
          <span
            v-else
            class="text-center"
          >
            -
          </span>

        </template>

        <template #cell(receta)="row">
          <b-form-checkbox
            v-model="row.item.requiere_receta"
            value="1"
            unchecked-value="0"
            :class="row.item.requiere_receta == 1 ? 'custom-control-danger' : 'custom-control-secondary'"
            name="check-button"
            switch
            @input="updateRequiereReceta(row.item)"
          >
            <span class="switch-icon-left">
              Sí
            </span>
            <span class="switch-icon-right">
              No
            </span>
          </b-form-checkbox>
        </template>

        <!-- Column: Status -->
        <template #cell(estado)="row">
          <b-badge

            :variant=" row.item.estado ? 'light-success' : 'light-danger'"
            class="text-capitalize"
          >
            {{ row.item.estado ? 'activo' : 'inactivo' }}
          </b-badge>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>

          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
    <ModalMedicamentoDetalles />
  </div>
</template>

<script>
import {
  BCard, BTable, BSpinner, BRow, BCol, BPagination, BBadge, BFormInput, BAvatar, BMedia, BDropdown,
  BDropdownItem, BProgress, BCardText, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import ModalMedicamentoDetalles from '@/views/salud/farmacia/medicamento-list/ModalMedicamentoDetalles.vue'
import medicamentoStoreModule from './medicamentoStoreModule'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBadge,
    BMedia,
    BAvatar,
    BProgress,
    BCardText,
    BFormCheckbox,

    // extern
    ModalMedicamentoDetalles,
  },
  data() {
    return {
      searchQuery: null,
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, 50],
      sortBy: 'id',
      sortDesc: false,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],

    }
  },
  methods: {
    updateRequiereReceta(item) {
      store.dispatch('medicamento-store/updateMedicamento', item)
        .then(() => {
          this.toastSuccess()
          this.actualizarTabla()
        })
        .catch(error => {
          Object.entries(error.response.data.error).forEach(([, value]) => {
            this.toastError(`${value[0]}`)
          })
          this.actualizarTabla()
        })
    },
    actualizarTabla() {
      this.$refs.refTablaMedicamentos.refresh()
    },
    async myProvider(ctx) {
      const promise = store.dispatch('medicamento-store/getMedicamentos', { queryParams: ctx })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        const items = data
        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0
        this.to = to || 0

        items.forEach((value, index) => {
          items[index].fecha_venc_dias = this.fecha_menor_en_dias(value.lotes_disponibles) <= 0 ? 0 : this.fecha_menor_en_dias(value.lotes_disponibles)
        })

        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },

    fecha_menor_en_dias(lotes) {
      if (lotes.length === 0) { return 0 }
      const arrayFechas = lotes.map(val => new Date(val.fecha_vencimiento))
      const min = new Date(Math.min.apply(null, arrayFechas))
      const fechaHoy = new Date().getTime()
      const fechaFin = min.getTime()
      const dias = (fechaFin - fechaHoy) / (1000 * 60 * 60 * 24)
      // min = `${min.getFullYear()}-${min.getMonth() + 1}-${min.getDate()}`
      return Math.round(dias)
    },
    verDetalles(item) {
      this.$emit('cargarDatosMedicamento', item)
      this.$bvModal.show('modal_detalles_medicamento')
    },
  },
  setup() {
    const MEDICAMENTO_APP_STORE_MODULE_NAME = 'medicamento-store'

    // Register module
    if (!store.hasModule(MEDICAMENTO_APP_STORE_MODULE_NAME)) store.registerModule(MEDICAMENTO_APP_STORE_MODULE_NAME, medicamentoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICAMENTO_APP_STORE_MODULE_NAME)) store.unregisterModule(MEDICAMENTO_APP_STORE_MODULE_NAME)
    })

    const fields = [
      { key: 'nombre_corto', label: 'MEDICAMENTO' },
      { key: 'presentacion', label: 'PRESENTACIÓN' },
      { key: 'forma', label: 'FORMA' },
      { key: 'stock', label: 'STOCK' },
      { key: 'receta', label: 'receta' },
      { key: 'estado' },
      { key: 'fecha_vencimiento', label: 'VENCIMIENTO' },
      { key: 'acciones' },
    ]

    return {
      avatarText,
      fields,
      medicamentoStoreModule,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
