<template>
  <div>
    <b-modal
      id="modal_detalles_medicamento"
      ok-only
      size="lg"
      class="p-5"
      title="Detalle Medicamento"
      modal-class="modal-primary"
    >
      <b-card
        no-body
        class="card-statistics"
      >
        <b-card-header class="border rounded mt-2 mx-2 mb-0">
          <b-card-title>
            <feather-icon
              icon="PackageIcon"
              size="19"
              class="text-primary"
            />
            <span
              class="text-primary mr-1"
              style="font-size:16px"
            ><strong>MEDICAMENTO:</strong> </span>
            <span style="font-size:14px">{{ medCargado.nombre_corto }}</span>
            <br>
            <span style="font-size:13px">
              Con receta:
              <b-badge
                :variant="medCargado.requiere_receta ? 'danger' : ''"
              >
                {{ medCargado.requiere_receta ? 'Sí' : 'No' }}
              </b-badge>
            </span>
          </b-card-title>
          <br>
          <small class="float-right text-success">{{ medCargado.presentacion.nombre }} <br>
            <span
              style="font-size:10px"
              class="text-secondary float-right"
            >Presentación</span></small>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col
              xl="3"
              sm="6"
              class="mb-2 mb-xl-0"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-success"
                  >
                    <feather-icon
                      size="24"
                      icon="TrendingUpIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h5 class="font-weight-bolder mb-0">
                    {{ medCargado.stock }}
                  </h5>
                  <b-card-text class="font-small-3 mb-0">
                    Stock total
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              xl="3"
              sm="6"
              class="mb-2 mb-xl-0"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-danger"
                  >
                    <feather-icon
                      size="24"
                      icon="HeartIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h6 class="font-weight-bolder mb-0">
                    {{ medCargado.forma.nombre_simplificado }}
                  </h6>
                  <b-card-text class="font-small-3 mb-0">
                    Forma
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              key="TrendingUpIcon"
              xl="3"
              sm="6"
              class="mb-2 mb-xl-0"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-info"
                  >
                    <feather-icon
                      size="24"
                      icon="PlusSquareIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h5 class="font-weight-bolder mb-0">
                    {{ medCargado.n_reg_sanitario }}
                  </h5>
                  <b-card-text class="font-small-3 mb-0">
                    N°Reg.Sanitario
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col
              xl="3"
              sm="6"
              class="mb-2 mb-xl-0"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-warning"
                  >
                    <feather-icon
                      size="24"
                      icon="LockIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h5 class="font-weight-bolder mb-0">
                    {{ medCargado.tipo.nombre }}
                  </h5>
                  <b-card-text class="font-small-3 mb-0">
                    Tipo
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>
      <b-card class="mt-1">
        <b-row>
          <b-col md="12">
            <div class="d-flex">
              <feather-icon
                icon="ListIcon"
                size="19"
                class="text-primary"
              />
              <h5 class="mb-1 ml-50 text-primary">
                Lista de lotes
              </h5>
            </div>
          </b-col>
        </b-row>
        <b-table
          :items="listaLotes"
          :fields="fields_listaLotes"
          :busy.sync="isBusy"
          small
          head-variant="light"
          :empty-text="'No hay registros para mostrar'"
        >
          <template #cell(fecha_vencimiento)="row">
            <span :class="row.item.fecha_venc_dias > 0 ? 'text-success' : 'text-danger'">
              {{ row.item.fecha_vencimiento }}
            </span>
          </template>
          <template #cell(fecha_venc_dias)="row">
            <!-- progress bar -->
            <b-row
              v-if="row.item.fecha_venc_dias > 0"
              class="avg-sessions pt-50"
            >
              <b-col
                cols="12"
                class="mb-2"
              >
                <b-card-text class="mb-50 text-center">
                  <small>{{ row.item.fecha_venc_dias }} dias para vencer</small>
                </b-card-text>
                <b-progress
                  animated
                  :value="row.item.fecha_venc_dias"
                  max="30"
                  height="10px"
                  :variant="row.item.fecha_venc_dias > 30 ? 'success' : 'danger'"
                />
              </b-col>
            </b-row>
            <span
              v-else
              class="text-center"
            >
              <b-badge variant="danger">
                vencido
              </b-badge>
            </span>
          </template>
        </b-table>
      </b-card>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="gradient-danger"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('modal_detalles_medicamento')"
          >
            Salir <feather-icon
              icon="LogInIcon"
              size="19"
            />
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BProgress, BCardText, BTable, BBadge,
  BCard, BCardHeader, BCardTitle, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import store from '@/store'

import { onUnmounted, onMounted } from '@vue/composition-api'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import atencionRecetaStore from '@/views/salud/atencion/atencion-receta/atencionRecetaStore'
// import vSelect from 'vue-select'

export default {
  components: {
    // vSelect,
    BButton,
    BRow,
    BCol,
    // BFormInput,
    BCard,
    // BFormTextarea,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BProgress,
    BTable,
    BBadge,
  },
  props: {
    /* data: {
      type: Array,
      default: () => [],
    }, */
  },
  data() {
    return {
      medCargado: {
        forma: {
          nombre_simplificado: '',
        },
        tipo: {
          nombre: '',
        },
        presentacion: {
          nombre: '',
        },
      },
      listaLotes: [],
      fields_listaLotes: [
        /* {
          key: 'index', label: 'N°',
        }, */
        {
          key: 'codigo', label: 'COMPRA', sortable: true, class: 'text-left',
        },
        { key: 'cantidad_compra', label: 'CANT. COMPRA', class: 'text-center' },

        { key: 'cantidad_disponible', label: 'CANT.DISPONIBLE', class: 'text-center' },
        { key: 'fecha_vencimiento', label: 'FECH. VENCIMIENTO', class: 'text-center' },
        { key: 'fecha_venc_dias', label: 'VENCIMIENTO', class: 'text-center' },
      ],
      isBusy: false,
      cadena_a_buscar: '',
    }
  },
  created() {
    this.$parent.$on('cargarDatosMedicamento', this.cargarDatosMedicamento)
  },
  mounted() {

  },
  methods: {
    cargarDatosMedicamento(item) {
      this.medCargado = item
      item.lotes_disponibles.forEach((value, index) => {
        this.medCargado.lotes_disponibles[index].fecha_venc_dias = this.fecha_venc_en_dias(value.fecha_vencimiento)
      })
      this.listaLotes = this.medCargado.lotes_disponibles
      // console.log('datosMedicamentoSeleccionado: ', item)
    },
    fecha_venc_en_dias(fecha) {
      const fechaHoy = new Date().getTime()
      const fechaFin = new Date(fecha).getTime()
      if (fechaHoy > fechaFin) { return 0 }
      const dias = (fechaFin - fechaHoy) / (1000 * 60 * 60 * 24)
      return Math.round(dias)
    },
  },
  setup() {
    const RECETA_STORE_MODULE_NAME = 'receta-module'

    // Register module
    if (!store.hasModule(RECETA_STORE_MODULE_NAME)) store.registerModule(RECETA_STORE_MODULE_NAME, atencionRecetaStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RECETA_STORE_MODULE_NAME)) store.unregisterModule(RECETA_STORE_MODULE_NAME)
    })

    onMounted(() => {
    })
    return {

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
