import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchMedicamento(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('servicio-salud/farmacia/producto', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addReceta(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/servicio-salud/farmacia/recetas', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteReceta(ctx, receta) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-salud/farmacia/recetas/${receta.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    showReceta(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-salud/farmacia/recetas/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMedicamentoReceta(ctx, data) {
      return new Promise((resolve, reject) => {
        // api/servicio-salud/farmacia/recetas/{receta}/detalles
        axios
          .post(`/servicio-salud/farmacia/recetas/${data.receta_id}/detalles`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeMedicamentoReceta(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`servicio-salud/farmacia/recetas/${data.receta_id}/detalles/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMedicamentoReceta(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`servicio-salud/farmacia/recetas/${data.receta_id}/detalles/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
