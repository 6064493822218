import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getMedicamentos(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('servicio-salud/farmacia/producto', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMedicamento(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`servicio-salud/farmacia/producto/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
